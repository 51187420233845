import { post } from '@/utils/request';

// 调仓统计-获取调仓统计默认或设置项
const getStatistical = (data) => post({
    url: '/store/statistical.warehouse/getStatistical',
    data: data
});

// 调仓统计-编辑调仓统计表
const editStatistical = (data) => post({
    url: '/store/statistical.warehouse/editStatistical',
    data: data
})


// 调仓统计-新增调仓统计表
const addStatistical = (data) => post({
    url: '/store/statistical.warehouse/addStatistical',
    data: data
})

// 调仓统计-获取调仓统计表列表
const getStatisticalList = (data) => post({
    url: '/store/statistical.warehouse/getStatisticalList',
    data: data
})

// 调仓统计-获取统计维度数据
const getTotalStatisticalData = (data) => post({
    url: '/store/statistical.warehouse/totalStatisticalData',
    data: data
})

// 调仓统计-获取雷达图数据
const getTotalStatisticalRadar = (data) => post({
    url: '/store/statistical.warehouse/totalStatisticalRadar',
    data: data
})

// 调仓统计-时间轴数据
const getTotalStatisticalTime = (data) => post({
    url: '/store/statistical.warehouse/totalStatisticalTime',
    data: data
})

// 导出
const downloadList = (data) => post({
    url: '/store/statistical.warehouse/downloadList',
    responseType: 'blob',
    data: data
})

// 删除
const deleteStatistical = (data) => post({
    url: '/store/statistical.warehouse/deleteStatistical',
    data: data
})

// 数据分析
const totalStatisticalData2D = (data) => post({
    url: '/store/statistical.warehouse/totalStatisticalData2D',
    data: data
})

// 数据分析-导出
const totalStatisticalData2DDownload = (data) => post({
    url: '/store/statistical.warehouse/totalStatisticalData2DDownload',
    responseType: 'blob',
    data: data
})
// 明细
const getTotalStatisticalList = (data) => post({
    url: '/store/statistical.warehouse/totalStatisticalList',
    data: data
})
export {
    getStatistical,
    editStatistical,
    addStatistical,
    getStatisticalList,
    getTotalStatisticalData,
    getTotalStatisticalRadar,
    getTotalStatisticalTime,
    downloadList,
    deleteStatistical,
    totalStatisticalData2D,
    totalStatisticalData2DDownload,
    getTotalStatisticalList
}